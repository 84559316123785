import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//公共模板
const layout = () => import('@/views/layout/')
// 登陆
const login = () => import('@/views/login/login')
// 工作台
const workbench = () => import('@/views/workbench/workbench')

const messageIndex = () => import('@/views/message/index')
const messageDetail = () => import('@/views/message/detail')

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  // 首页
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      // 页面标题title
      title: '登陆'
    },
  },
  // 工作台
  {
    path: '/workbench',
    name: 'workbench',
    component: layout,
    redirect: '/workbench/index',
    meta: {
      // 页面标题title
      title: '工作台'
    },
    children: [
      {
        path: 'index',
        name: '课程管理',
        component: workbench,
      },
      // 课程筛选
      {
        path: 'courseFilter',
        name: 'courseFilter',
        component: () => import('@/views/workbench/courseFilter'),
        meta: {
          // 页面标题title
          title: '专题课程'
        },
      },
      // 专题课程
      {
        path: 'specialCourse',
        name: 'specialCourse',
        component: () => import('@/views/workbench/specialCourse'),
        meta: {
          // 页面标题title
          title: '专题课程'
        },
      },
      
      // 专题课程视频
      {
        path: 'specialCourseVideo',
        name: 'specialCourseVideo',
        component: () => import('@/views/workbench/specialCourseVideo'),
        meta: {
          // 页面标题title
          title: '专题课程视频'
        },
      },
    ]
  },
  //消息
  {
    path: '/message',
    name: 'message',
    component: layout,
    redirect: '/message/index',
    meta: {
      // 页面标题title
      title: '消息中心'
    },
    children: [
      {
        path: 'index',
        name: '消息中心',
        component: messageIndex,
      }, {
        path: 'detail',
        name: '消息详情',
        component: messageDetail,
      },

    ]
  },
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('access_token');
  if (to.path == "/login") {
    if(token) {
      next({
        path:'/workbench/courseFilter'
      })
    }
    sessionStorage["quit"] = 1;
  } else {
    sessionStorage["quit"] = 0;
  }
  if(to.name == 'login'){
    next();
  }else{
    if (token) {
      next();
    } else {
      next({path:'/login'})
      sessionStorage["quit"] = 1;
    }
  }
  
});




export default router
