import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

import 'lib-flexible/flexible.js'

import { Base64 } from 'js-base64';
import Mui from 'vue-awesome-mui';
Vue.use(Mui);

// import '../public/fonts/definedFont.css';
import axios from 'axios';
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios

Vue.use(Base64);

// import {checkLogin} from './util/checkLogin.js';

// require('videojs-contrib-hls/dist/videojs-contrib-hls.js')

Vue.use(ElementUI);
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
